/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    loadLtiItemsIfNeeded,
    publishLtiItemAsComposition,
    unpublishLtiItemAsComposition,
    createLtiItem,
    //duplicateLtiItem,
    deleteLtiItem,
} from '../../store/lti/lti.js';
import { previewLtiItem } from '../../store/preview/preview.js';
import { navigate } from '../../store/route/route.js';
import { cancelBubble } from '../../helpers/events.js';
import { translate } from '../../helpers/translate.js';
import { renderTimestamp } from '../../helpers/controls.js';
import '@lit-labs/virtualizer';
import '@polymer/app-layout/app-layout.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@material/web/fab/fab.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-search-input.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-status-message.js';
import '../shared/sdb-dialog.js';

const mapStateToProps = (state) => ({
    ltiItems: state.lti.list || [],
    total: state.lti.total,
    search: state.lti.search,
    isLoadingFirstTime: state.lti.status === 'loading' && state.lti.page === -1,
    user: state.auth.user,
    settings: state.env.settings,
    portalId: state.env.portalIdentifier,
    hasGlobalId: state.env.hasGlobalId,
});

const mapDispatchToProps = (dispatch) => ({
    loadLtiItemsIfNeeded: (args) => dispatch(loadLtiItemsIfNeeded(args)),
    publishLtiItemAsComposition: (item) => dispatch(publishLtiItemAsComposition({ item })),
    unpublishLtiItemAsComposition: (item) => dispatch(unpublishLtiItemAsComposition({ item })),
    createLtiItem: (name) => dispatch(createLtiItem({ name })),
    deleteLtiItem: (id) => dispatch(deleteLtiItem({ id })),
    previewLtiItem: (item) => dispatch(previewLtiItem({ item })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-lti-item-list')
class SdbLtiItemList extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor ltiItems;

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoadingFirstTime;

    @state()
    accessor user;

    @state()
    accessor args;

    @query('#new_name')
    accessor newName;

    get downloadLtiItemsCsvUrl() {
        return `${this.settings.exportsEndpoint}ltiitems/${this.portalId}`;
    }

    static styles = [
        sharedStyles,
        css`
            :host {
                max-width: var(--tcg-content-max-width);
                width: 100%;
            }

            .right-nav-bar {
                display:flex;
                align-items: center;
                justify-content: space-between;
            }

            sdb-search-input {
                flex-grow: 1;
            }

            sdb-content-iconbutton {
                margin-left: 10px;
            }

            #lti_items_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            paper-item {
                cursor: pointer;
            }

            paper-icon-button {
                color: var(--icon-button-color);
            }

            paper-listbox {
                min-width: 200px;
            }

            .no-results {
                width: 100%;
                max-width: var(--tcg-content-max-width);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #no_results_img {
                width: 400px;
                height: 400px;
                background-color: var(--tcg-secondary-background-color);
            }
        `,
    ];

    render() {
        if (this.isLoadingFirstTime) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-layout-60-40>
                <sdb-nav-titlebar slot="left">
                    <span>${translate('headerLtiItems')}</span>
                    <span ?hidden=${!this.total}>&nbsp;(${this.total})</span>
                </sdb-nav-titlebar>
                <div class="right-nav-bar" slot="right">
                    <sdb-search-input
                        placeholder=${translate('search')}
                        value=${this.search}
                        @search=${(e) => { this.loadLtiItemsIfNeeded({ search: e.detail.searchText, reload: true }); }}>
                    </sdb-search-input>
                    <a href=${this.downloadLtiItemsCsvUrl}>
                        <sdb-content-iconbutton icon="fal fa-file-csv" size="24"></sdb-content-iconbutton>
                    </a>
                </div>
            </sdb-layout-60-40>
            <sdb-layout-100>
                ${!this.total
                    ? html`<sdb-status-message label=${translate('noLtiItemsFound')}></sdb-status-message>`
                    : html`
                        <sdb-content-list separator>
                            <lit-virtualizer
                                id="lti_items_list"
                                .items=${this.ltiItems}
                                .renderItem=${(ltiItem, index) => this._renderLtiItemListItem(ltiItem, index)}
                                .scrollTarget=${document}>
                            </lit-virtualizer>
                        </sdb-content-list>
                    `
                }
                <md-fab @click=${()=> { this._openDialog('add', null); }}>
                    <sdb-content-icon
                        slot="icon"
                        icon="fal fa-plus"
                        size="24">
                    </sdb-content-icon>
                </md-fab>
            </sdb-layout-100>

            <sdb-dialog
                type="confirm"
                id="publish_dialog"
                heading=${translate('publishHeadingLtiItem')}
                body=${translate('publishBodyLtiItem')}
                confirmCaption=${translate('publish')}
                @confirm=${(e) => { this.publishLtiItemAsComposition(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="unpublish_dialog"
                heading=${translate('unpublishHeadingLtiItem')}
                body=${translate('unpublishBodyLtiItem')}
                confirmCaption=${translate('unpublish')}
                @confirm=${(e) => { this.unpublishLtiItemAsComposition(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="delete_dialog"
                heading=${translate('deleteHeadingtiItem')}
                confirmCaption=${translate('delete')}
                @confirm=${(e) => { this.deleteLtiItem(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="form"
                id="add_dialog"
                heading=${translate('createHeadingtiItem')}
                confirmCaption=${translate('create')}
                @confirm=${() => { this.createLtiItem(this.newName.value); this.newName.value = ''; }}>
                <paper-input
                    id="new_name"
                    transparent
                    style="width: 500px;"
                    label=${translate('name')}>
                </paper-input>
            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: false,
                },
            }),
        );
    }

    _renderLtiItemListItem(ltiItem, index) {
        this.loadLtiItemsIfNeeded({ index });

        return html`
            <sdb-content-listitem
                icon="fal fa-square-l"
                .headline=${ltiItem.title}
                .subtitle=${this._getSubtitle(ltiItem)}
                @click=${()=> { this.navigate(`ltiitem/${ltiItem.targetId}`); }}>

                <sdb-content-chipbar slot="chipbar">
                    <sdb-content-chip label="catalogus" ?hidden=${ltiItem.elearningType !== 0}></sdb-content-chip>
                </sdb-content-chipbar>

                <paper-menu-button
                    slot="secondaryAction"
                    horizontal-align="right"
                    vertical-align="bottom"
                    @click=${cancelBubble}>
                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                    <paper-listbox slot="dropdown-content">
                        <paper-item
                            ?hidden=${this.hasGlobalId}
                            ?disabled=${ltiItem.elearningType === 0}
                            @click=${()=> { this._openDialog('publish', ltiItem); }}>
                            ${translate('publish')}
                        </paper-item>
                        <paper-item
                            ?hidden=${this.hasGlobalId}
                            ?disabled=${ltiItem.elearningType === 1}
                            @click=${()=> { this._openDialog('unpublish', ltiItem); }}>
                            ${translate('unpublish')}
                        </paper-item>
                        <paper-item
                            @click=${()=> { this.previewLtiItem(ltiItem); }}>
                            ${translate('preview')}
                        </paper-item>
                    </paper-listbox>
                </paper-menu-button>

            </sdb-content-listitem>
        `;
    }

    _getSubtitle(ltiItem) {
        var s = `${translate('lastModified')}: ${renderTimestamp(ltiItem.lastModified)}`;
        if (ltiItem.supplier && ltiItem.supplier.supplierKey) {
            s += ` | ${ltiItem.supplier.name}`;
        }
        if (ltiItem.description) {
            s += ` | ${ltiItem.description}`;
        }
        return s;
    }

    _openDialog(action, args) {
        this.renderRoot.querySelector(`#${action}_dialog`).open(args);
    }
}

