/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { loadElearnings, loadFilters } from '../../store/elearning/elearning.js';
import { translate } from '../../helpers/translate.js';
import {
    toSelectItems,
    getSelectedItemValue,
    toSelectedItemValue,
 } from '../../helpers/controls.js';
 import { getPlayerType } from '../../store/elearning/helpers.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '../shared/sdb-dialog.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-search-input.js';

const mapStateToProps = (state) => ({
    items: state.elearning.list,
    total: state.elearning.total,
    filters: state.elearning.filters,
    playerTypes: state.elearning.playerTypes || [],
    suppliers: state.elearning.suppliers || [],
    isLoadingFilters: state.elearning.status === 'loadingFilters' || !state.elearning.playerTypes,
    isLoadingInitialList: state.elearning.status === 'loading' && state.page === -1,
});

const mapDispatchToProps = (dispatch) => ({
    loadInitialList: () => dispatch(loadElearnings({ reload: true })),
    loadNextPage: (index) => dispatch(loadElearnings({ index })),
    loadFilters: () => dispatch(loadFilters({ reload: false })),
    filter: (filters) => dispatch(loadElearnings({ filters, reload: true })),
});

@customElement('sdb-elearning-select-dialog')
class SdbElearningSelectDialog extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor items;

    @state()
    accessor total;

    @state()
    accessor filters = {};

    @state()
    accessor playerTypes = [];

    @state()
    accessor suppliers = [];

    @state()
    accessor isLoadingFilters = false;

    @state()
    accessor isLoadingInitialList = false;

    @state()
    accessor selectedListItem = null;

    @state()
    accessor selectedValue = null;

    @query('#select_el_dialog')
    accessor selectDialog;

    @query('#search')
    accessor searchInput;

    @query('#el_list')
    accessor listbox;

    constructor() {
        super();
        this.sender = null;
    }

    static get styles() {
        return css`
            .filter-bar {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .filter-bar sdb-search-input {
                margin-top: 3px;
                width: 50%;
            }

            .filter-bar .filters {
                display: flex;
                justify-content: space-between;
                width: 40%;
            }

            .filters #supplier_filter {
                width: 50%;
            }

            .filters #playertype_filter {
                width: 40%;
            }

            .list {
                height: 550px;
                width: 900px;
                overflow-y: auto;
            }

            #el_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            sdb-content-listitem.selected {
                font-weight: bold;
                background: rgba(0, 0, 0, 0.1);
            }
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('openElearningSelectDialog', this._openDialog.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('openElearningSelectDialog', this._openDialog.bind(this))
    }

    render() {
        return html`
            <sdb-dialog
                type="form"
                id="select_el_dialog"
                heading=${translate('chooseElearning')}
                confirmCaption=${translate('select')}
                .confirmDisabled=${this.selectedListItem === null}
                @confirm=${this._confirm}
                @cancel=${this._reset}>

                <div class="filter-bar">
                    <sdb-search-input
                        placeholder=${translate('search')}
                        value=${this.filters.search}
                        has-border
                        @search=${(e) => { this.filter({ search: e.detail.searchText }); }}>
                    </sdb-search-input>

                    ${this.isLoadingFilters
                        ? html`<sdb-loading></sdb-loading>`
                        : html`
                            <div class="filters">
                                <vaadin-select
                                    id="supplier_filter"
                                    transparent
                                    label=${translate('supplier')}
                                    value=${toSelectedItemValue(this.filters.supplier)}
                                    @change=${(e) => { this.filter({ supplier: getSelectedItemValue(e) }); }}
                                    .items=${toSelectItems(this.suppliers, translate('all'))}>
                                </vaadin-select>
                                <vaadin-select
                                    id="playertype_filter"
                                    transparent
                                    label=${translate('playerType')}
                                    value=${toSelectedItemValue(this.filters.playerType)}
                                    @change=${(e) => { this.filter({ playerType: getSelectedItemValue(e) }); }}
                                    .items=${toSelectItems(this.playerTypes, translate('all'))}>
                                </vaadin-select>
                            </div>
                        `}
                </div>

                <div class="list">
                    ${this.isLoadingInitialList || !this.items
                        ? html`<sdb-loading></sdb-loading>`
                        : html`
                            ${!this.total
                                ? html`<sdb-status-message label=${translate('noElearningsFound')}></sdb-status-message>`
                                : html`
                                    <sdb-content-list id="el_list">
                                        <lit-virtualizer
                                            .items=${this.items}
                                            .renderItem=${(elearning, index) => this._renderElearningListItem(elearning, index)}
                                            .scrollTarget=${document}>
                                        </lit-virtualizer>
                                    </sdb-content-list>
                                `
                            }
                        `
                    }
                <div>
            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.loadInitialList();
        this.loadFilters();
    }

    _renderElearningListItem(elearning, index) {
        this.loadNextPage(index);

        return html`
            <sdb-content-listitem
                icon=${this._getIcon(elearning)}
                headline=${elearning.title}
                overline=${getPlayerType(elearning.playerType)}
                subtitle=${this._getSubTitle(elearning)}
                @click=${(e) => { this._select(elearning, e.target) }}>
            </sdb-content-listitem>
        `;
    }

    _openDialog(e) {
        this.sender = e.detail.sender;
        this.selectDialog.open();
    }

    _select(elearning, target) {
        if (this.selectedListItem === target) {
            this._reset();
            return;
        }

        if (this.selectedListItem) {
            this.selectedListItem.classList.remove('selected');
        }

        this.selectedListItem = target;
        this.selectedValue = elearning;

        this.selectedListItem.classList.add('selected');
    }

    _confirm() {
        this.dispatchEvent(new CustomEvent('elearningSelected', {
            detail: {
                elearning: this.selectedValue,
                sender: this.sender,
            },
            composed: true,
            bubbles: true,
        }));

        this._reset();
    }

    _reset() {
        if (this.selectedListItem) {
            this.selectedListItem.classList.remove('selected');
        }

        this.selectedListItem = null;
        this.selectedValue = null;
    }

    _getIcon(elearning) {
        switch (elearning?.playerType) {
            case 0: return 'fal fa-square-l';
            case 1: return 'fal fa-square-o';
            case 2: return 'fal fa-square-s';
            default: return 'fal fa-question';
        }
    }

    _getSubTitle(elearning) {
        let s = elearning.supplier?.name || '';
        s += elearning.supplier?.name && elearning.description ? ' | ' : '';
        s += elearning.description || '';
        return s;
    }
}


