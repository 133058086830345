import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { loadAccreditations } from '../../store/accreditation/accreditation.js';
import { navigate } from '../../store/route/route.js';
import sharedStyles from '../../app/styles.js';
import { translate } from '../../helpers/translate.js';
import { formatDate } from '../../helpers/controls.js';
import '@lit-labs/virtualizer';
import '@vaadin/form-layout/theme/material/vaadin-form-layout.js';
import '../shared/sdb-status-message.js';
import '../shared/sdb-overlay-detail.js';

const mapStateToProps = (state) => ({
    accreditations: state.accreditation.list,
    name: state.comp.current?.composition.title,
    total: state.accreditation.total,
    loadedId: state.accreditation.targetId,
});

const mapDispatchToProps = (dispatch) => ({
    loadNextPage: (targetId) => dispatch(loadAccreditations({ targetId })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-composition-accreditations')
class SdbCompositionAccreditations extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {
    @property({type: String})
    accessor targetId;

    @state()
    accessor accreditations = [];

    @state()
    accessor name = '';

    @state()
    accessor total = 0;

    static get styles() {
        return [
            sharedStyles,
            css`
                :host {
                    display: block;
                }

                vaadin-text-field[readonly] {
                    --_material-text-field-input-line-opacity: 0;
                }

                [slot=content] {
                    width: 100%;
                }
            `,
        ];
    }

    render() {
        if (this.targetId !== this.loadedId) {
            return nothing;
        }

        return html`
            <sdb-overlay-detail
                headline=${this.name}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('compositions') }>

                <div slot="content">
                    ${!this.total ? html`
                        <sdb-status-message label=${translate('noAccreditationsFound')}>
                        </sdb-status-message>
                    ` : html`
                        <sdb-layout-100>

                            <sdb-content-accordion separator>

                                <sdb-content-accordionheading class="hideonmobile">
                                    <sdb-content-headingitem
                                        textDark
                                        slot="1"
                                        icon="fal fa-user"
                                        headline=${translate('user')}>
                                    </sdb-content-headingitem>
                                    <sdb-content-headingitem
                                        textDark
                                        slot="2"
                                        icon="fal fa-users"
                                        headline=${translate('pe.organization')}>
                                    </sdb-content-headingitem>
                                    <sdb-content-headingitem
                                        textDark
                                        slot="3"
                                        icon="fal fa-calendar"
                                        headline=${translate('processed')}>
                                    </sdb-content-headingitem>
                                    <sdb-content-headingitem
                                        textDark
                                        slot="4"
                                        icon="fal fa-check"
                                        headline=${translate('Status')}>
                                    </sdb-content-headingitem>
                                </sdb-content-accordionheading>

                                <lit-virtualizer
                                    id="accreditations_list"
                                    .items=${this.accreditations}
                                    totalItems=${this.total}
                                    .renderItem=${(accreditation, index) => this._renderAccreditationItem(accreditation, index)}
                                    .scrollTarget=${document}>
                                </lit-virtualizer>

                            </sdb-content-accordion>

                        </sdb-layout-100>
                    `}
                </div>
            </sdb-overlay-detail>
        `;
    }

    _renderAccreditationItem(accreditation, index) {
        if (index === this.accreditations.length - 1 && index !== this.total - 1) {
            this.loadNextPage(this.targetId);
        }

        return html`
            <sdb-content-accordionitem
                headline=${accreditation.displayName}>
                <div slot="2">${accreditation.accreditationKey}</div>
                <div slot="3">${formatDate(accreditation.created)}</div>
                <div slot="4">${
                    this._renderStatusChip(accreditation.result.resultStatus, translate('accr.status.' + accreditation.result.resultStatus))}
                </div>

                <vaadin-form-layout>
                    ${this._renderField(translate('status'), translate('accr.status.' + accreditation.result.resultStatus))}
                    <div></div>

                    ${this._renderField(translate('pe.organization'), accreditation.accreditationKey)}
                    ${this._renderField(translate('processed'), formatDate(accreditation.created))}

                    ${accreditation.result.message ? this._renderField(translate('result'), accreditation.result.message, 2) : nothing}

                    ${this._renderField(translate('pe.externalPersonId'), accreditation.data.externalPersonId)}
                    ${this._renderField(translate('points'), accreditation.points)}

                    ${this._renderField(translate('pe.courseId'), accreditation.data.course.peCourseId)}
                    ${this._renderField(translate('accr.endDate'), formatDate(accreditation.data.endDate))}

                    ${accreditation.data.course.peModuleId
                        ? this._renderField(translate('pe.moduleId'), accreditation.data.course.peModuleId)
                        : nothing
                    }
                    ${accreditation.data.course.peEditionId
                        ? this._renderField(translate('pe.editionId'), accreditation.data.course.peEditionId)
                        : nothing
                    }
                </vaadin-form-layout>
            </sdb-content-accordionitem>
        `;
    }

    _renderField(label, value, colspan = 1) {
        return html`
            <vaadin-text-field
                readonly
                colspan=${colspan}
                label=${label}
                value=${value}>
            </vaadin-text-field>
        `;
    }

    _renderStatusChip(status, label) {
        return html`
            <sdb-content-chipbar>
                <sdb-content-chip
                    light
                    label=${label}
                    color=${this._getStatusColor(status)}>
                </sdb-content-chip>
            </sdb-content-chipbar>
        `;
    }

    _getStatusColor = (status) => {
        switch (status) {
            case 'accepted':
                return 'green';
            case 'badResponse':
            case 'error':
            case 'notValid':
            case 'failed':
                return 'firebrick';
            case 'pending':
                return 'grey';
        }
    };
}
