/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import { loadOsItemsIfNeeded } from '../../store/os/os.js';
import { previewElearning } from '../../store/preview/preview.js';
import { cancelBubble } from '../../helpers/events.js';
import { translate } from '../../helpers/translate.js';
import { renderTimestamp } from '../../helpers/controls.js';
import '@lit-labs/virtualizer';
import '@polymer/app-layout/app-layout.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-search-input.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-status-message.js';
import '../shared/sdb-dialog.js';

const mapStateToProps = (state) => ({
    osItems: state.os.list || [],
    total: state.os.total,
    search: state.os.search,
    isLoadingFirstTime: state.os.status === 'loading' && state.os.page === -1,
});

const mapDispatchToProps = (dispatch) => ({
    loadOsItemsIfNeeded: (args) => dispatch(loadOsItemsIfNeeded(args)),
    previewElearning: (item) => dispatch(previewElearning({ item })),
});

@customElement('sdb-os-item-list')
class SdbOsItemList extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor osItems;

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoadingFirstTime;

    @state()
    accessor user;

    @state()
    accessor args;

    static styles = [
        sharedStyles,
        css`
            :host {
                max-width: var(--tcg-content-max-width);
                width: 100%;
            }

            .right-nav-bar {
                display:flex;
                align-items: center;
                justify-content: space-between;
            }

            sdb-search-input {
                flex-grow: 1;
            }

            sdb-content-iconbutton {
                margin-left: 10px;
            }

            #os_items_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            paper-item {
                cursor: pointer;
            }

            paper-icon-button {
                color: var(--icon-button-color);
            }

            paper-listbox {
                min-width: 150px;
            }

            .no-results {
                width: 100%;
                max-width: var(--tcg-content-max-width);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #no_results_img {
                width: 400px;
                height: 400px;
                background-color: var(--tcg-secondary-background-color);
            }
        `,
    ];

    render() {
        if (this.isLoadingFirstTime) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-layout-60-40>
                <sdb-nav-titlebar slot="left">
                    <span>${translate('headerOsItems')}</span>
                    <span ?hidden=${!this.total}>&nbsp;(${this.total})</span>
                </sdb-nav-titlebar>
                <div class="right-nav-bar" slot="right">
                    <sdb-search-input
                        placeholder=${translate('search')}
                        value=${this.search}
                        @search=${(e) => { this.loadOsItemsIfNeeded({ search: e.detail.searchText, reload: true }); }}>
                    </sdb-search-input>
                </div>
            </sdb-layout-60-40>
            <sdb-layout-100>
                ${!this.total
                    ? html`<sdb-status-message label=${translate('noOsItemsFound')}></sdb-status-message>`
                    : html`
                        <sdb-content-list separator>
                            <lit-virtualizer
                                id="os_items_list"
                                .items=${this.osItems}
                                .renderItem=${(osItem, index) => this._renderOsItemListItem(osItem, index)}
                                .scrollTarget=${document}>
                            </lit-virtualizer>
                        </sdb-content-list>
                    `
                }
            </sdb-layout-100>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: false,
                },
            }),
        );
    }

    _renderOsItemListItem(osItem, index) {
        this.loadOsItemsIfNeeded({ index });

        return html`
            <sdb-content-listitem
                icon="fal fa-square-o"
                .headline=${osItem.title}
                .subtitle=${this._getSubtitle(osItem)}>

                <sdb-content-chipbar slot="chipbar">
                    <sdb-content-chip label="catalogus" ?hidden=${osItem.elearningType !== 0}></sdb-content-chip>
                </sdb-content-chipbar>

                <paper-menu-button
                    slot="secondaryAction"
                    horizontal-align="right"
                    vertical-align="bottom"
                    @click=${cancelBubble}>
                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                    <paper-listbox slot="dropdown-content">
                        <paper-item @click=${()=> { this.previewElearning(osItem); }}>${translate('preview')}</paper-item>
                    </paper-listbox>
                </paper-menu-button>

            </sdb-content-listitem>
        `;
    }

    _getSubtitle(osItem) {
        var s = `${translate('lastModified')}: ${renderTimestamp(osItem.lastModified)}`;
        if (osItem.supplier && osItem.supplier.supplierKey) {
            s += ` | ${osItem.supplier.name}`;
        }
        if (osItem.description) {
            s += ` | ${osItem.description}`;
        }
        return s;
    }
}
