import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import  { httpGet } from '../../helpers/http.js';
import environments from '../../app/environments.js'

export const GLOBAL_ID = 'GLOBAL';

export const loadUserSettings = createAsyncThunk(
    'env/userSettings',
    async ({ environment }, { getState }) => {
        const { env, auth } = getState();

        const settings = env.settings ?? environments[ensure(environment)];

        return await httpGet(settings.settingsEndpoint, auth.user);
    },
);

const envSlice = createSlice({
    name: 'env',
    initialState: {
    },
    reducers: {
        initialize: (state, action) => {
            const { payload } = action;
            if (!payload) {
                return;
            }

            let { env, portalIdentifier } = payload.settings;

            state.portalIdentifier = portalIdentifier || GLOBAL_ID;
            state.hasGlobalId = portalIdentifier === GLOBAL_ID;
            state.settings = environments[ensure(env)];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUserSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadUserSettings.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.userSettings = payload;
            })
            .addCase(loadUserSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

const ensure = (env) => ['development', 'production'].indexOf(env) === -1 ? 'development' : env;

export const { initialize } = envSlice.actions;

export default envSlice.reducer;